import React from "react";
import "./App.css";
import { MapContainer, TileLayer, Polyline } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import CustomCoordinates from "./CustomCoordinates";
import {
  getParameterByName,
  preProcessBusesRoutes,
  preProcessRouteCenteredPoint,
  preProcessRoutes,
  preProcessStops,
  sendMessage,
  getcurrentlocation,
} from "./helpers";
import MapRoutes from "./MapRoutes";
import BusCoordinates from "./BusCoordinates";
import Buses from "./Buses";

const { useState, useEffect } = React;
const regex = /react/g;
const edgeBuffer = require("leaflet-edgebuffer");

function MapPlaceholder() {
  return (
    <p>
      Loading ...
      <noscript>You need to enable JavaScript to see this map.</noscript>
    </p>
  );
}

const DEFAULT_ROUTE_OPTIONS = { color: "#268ec6" };
const DEFAULT_ZOOM_LEVEL = 16;

function App() {
  const [customCoordinates, setCustomCoordinates] = useState();
  const [userLocation, setUserLocation] = useState();
  const [busesLocations, setBusesLocations] = useState();
  const [skipMarker, setSkipMarker] = useState(false);
  const [center, setCenter] = useState();
  const [route, setRoute] = useState();
  const [routeOptions, setRouteOptions] = useState();
  const [routeStops, setRouteStops] = useState(null);
  const [routeStartingPoint, setRouteStartingPoint] = useState();
  const [walkingPaths, setWalkingPaths] = useState([]);
  const [busRoutes, setBusRoutes] = useState([]);

  useEffect(() => {
    const lat = getParameterByName("lat");
    const lng = getParameterByName("lng");
    const marker = getParameterByName("marker");
    const defaultCenter = [30.0270893, 31.5241508];
    if (lat && lng) {
      setUserLocation({
        coordinates: [lat, lng],
      });
      setCustomCoordinates({
        coordinates: [lat, lng],
      });
      setCenter([lat, lng]);
    } else {
      setCustomCoordinates({
        coordinates: defaultCenter,
      });
      setCenter(defaultCenter);
    }
    if (marker) {
      setSkipMarker(true);
    }
  }, []);

  function handleIFrameCommunication(event) {
    const { data = {} } = event;
    if (!regex.test(data.source)) {
      const { action = {}, payload = [], extraOptions, content = "" } = data;
      try {
        if (action === "coordinates") {
          if (payload) {
            setCustomCoordinates({
              coordinates: payload.split(","),
              color: extraOptions && extraOptions.color,
            });
          }
        } else if (action === "message") {
          window.alert(`Message received is ${content}`);
          sendMessage(data);
          window.alert("Message is sent back to same origin too");
        } else if (action === "route") {
          const processedRoutes = preProcessRoutes(payload);
          setRoute(processedRoutes);
          if (extraOptions) {
            const { color, stops, zoomLevel } = extraOptions;
            if (stops) {
              setRouteStops(preProcessStops(stops));
            }
            if (color) {
              setRouteOptions({ color: extraOptions.color });
            } else {
              setRouteOptions(DEFAULT_ROUTE_OPTIONS);
            }

            setRouteStartingPoint({
              coordinates:
                preProcessRouteCenteredPoint(extraOptions.routeCenterPoint) ||
                processedRoutes[0],
              zoomLevel,
            });
          } else {
            setRouteOptions(DEFAULT_ROUTE_OPTIONS);
            setRouteStartingPoint({
              coordinates: processedRoutes[0],
              zoomLevel: DEFAULT_ZOOM_LEVEL,
            });
          }
        } else if (action === "clearRoutes") {
          setRoute();
          setBusesLocations();
        } else if (action === "reset") {
          setRoute();
          setRouteOptions();
          setUserLocation();
          setRouteStops();
        } else if (action === "userLocation") {
          const [lat, lng] = payload.split(",");
          setUserLocation({
            coordinates: [lat, lng],
            color: extraOptions && extraOptions.color,
            fly: extraOptions && extraOptions.fly,
          });
          setCustomCoordinates({
            coordinates: [lat, lng],
          });
          setCenter([lat, lng]);
        } else if (action === "clearUserLocation") {
          setUserLocation();
        } else if (action === "busesLocations") {
          setBusesLocations({
            coordinates: JSON.parse(payload),
            color: extraOptions && extraOptions.color,
          });
        } else if (action === "clearBusLocation") {
          setBusesLocations();
        } else if (action === "otp") {
          const road = JSON.parse(payload);
          const [lat, lng] = road[0].steps[0];
          setUserLocation({
            coordinates: [lat, lng],
            color: extraOptions && extraOptions.color,
            fly: extraOptions && extraOptions.fly,
          });
          setCustomCoordinates({
            coordinates: [lat, lng],
          });
          setCenter([lat, lng]);
          const walkingSegments = road.filter(
            (segment) => segment.mode === "WALK"
          );
          const tripSegments = road.filter((segment) => segment.mode === "BUS");

          const processedBusRoutes = tripSegments.map((segment) => ({
            route: preProcessRoutes(segment.route),
            extraOptions: segment.extraOptions || {},
          }));
          setBusRoutes(processedBusRoutes);

          setWalkingPaths(walkingSegments.map((segment) => segment.steps));
        }
      } catch (e) {
        sendMessage(e);
      }
    }
  }

  useEffect(() => {
    window.addEventListener("message", handleIFrameCommunication);
    return () => {
      window.removeEventListener("message", handleIFrameCommunication);
    };
  }, []);

  return (
    <div className="App">
      {customCoordinates && Boolean(!route) && !skipMarker && (
        <div className="map-marker-centered" />
      )}
      {center && (
        <MapContainer
          className="map"
          zoomControl={false}
          center={center}
          zoom={16}
          placeholder={<MapPlaceholder />}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            edgeBufferTiles="2"
            headers={{
              "User-Agent": "Midar-Map/1.0 (devops@mwasla.tech)",
            }}
          />

          {walkingPaths.length > 0 &&
            walkingPaths.map((path, index) => (
              <Polyline
                key={index}
                positions={path}
                pathOptions={{
                  color: "gray",
                  weight: 4,
                  dashArray: "8 12",
                  lineJoin: "round",
                }}
              />
            ))}
          {customCoordinates && (
            <CustomCoordinates
              coordinates={customCoordinates.coordinates}
              color={customCoordinates.color}
              liveUpdate={Boolean(!route)}
              showFixedMarker={Boolean(route)}
              flyTo={true}
            />
          )}
          {userLocation && (
            <CustomCoordinates
              coordinates={userLocation.coordinates}
              color={userLocation.color}
              showFixedMarker={true}
              flyTo={userLocation.fly}
            />
          )}
          {busesLocations && (
            <Buses
              busesLocations={busesLocations.coordinates}
              routeOptions={routeOptions}
              color={busesLocations.color}
            />
          )}
          {busRoutes.length > 0 &&
            busRoutes.map((bus, index) => (
              <React.Fragment key={index}>
                <Polyline
                  positions={bus.route}
                  pathOptions={{
                    color: bus.extraOptions.color || "green",
                    weight: 4,
                  }}
                />

                {bus.extraOptions.stops &&
                  bus.extraOptions.stops.map((stop, stopIndex) => (
                    <CustomCoordinates
                      key={stopIndex}
                      coordinates={[stop.latitude, stop.longitude]}
                      color={bus.extraOptions.color || "green"}
                      showFixedMarker={true}
                    />
                  ))}
              </React.Fragment>
            ))}

          {route && routeStartingPoint && (
            <CustomCoordinates
              coordinates={routeStartingPoint.coordinates}
              color={routeStartingPoint.color}
              flyTo={true}
              zoomLevel={routeStartingPoint.zoomLevel}
            />
          )}
          {route && (
            <MapRoutes
              polylineCoordinates={route}
              options={routeOptions}
              stops={routeStops}
            />
          )}
        </MapContainer>
      )}
    </div>
  );
}

export default App;
